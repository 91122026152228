<template>
  <q-page class="q-pa-lg">
    <loading-alert :display="displayLoading"></loading-alert>
    <ygo-alert
      :display="displayAlert"
      :title="alertTitle"
      :message="alertMessage"
      :type="alertType"
      @accept="
        displayAlert = false;
        statusDialog = false;
      "
    ></ygo-alert>
    <div class="row q-mb-lg">
      <div class="text-h5">
        <div class="col q-px-md">Facturas Y-Gó</div>
      </div>
    </div>
    <div class="row q-mb-lg">
      <div class="col-lg-2 q-px-md">
        <q-input dense filled label="Casillero" ref="box" v-model="searchBox" />
      </div>
      <div class="col-lg-2 q-px-md">
        <q-input
          filled
          mask="date"
          label="Fecha inicial"
          dense
          v-model="dateToday"
        >
          <template v-slot:append>
            <q-icon name="fas fa-calendar" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="dateToday"
                  @input="() => $refs.qDateProxy.hide()"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Cerrar" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-lg-2 q-px-md">
        <q-input
          filled
          mask="date"
          label="Fecha final"
          dense
          v-model="dateTomorow"
        >
          <template v-slot:append>
            <q-icon name="fas fa-calendar" class="cursor-pointer">
              <q-popup-proxy
                ref="qDateProxyt"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="dateTomorow"
                  @input="() => $refs.qDateProxyt.hide()"
                >
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Cerrar" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div class="col-lg-2 q-px-md">
        <q-select
          :options="statusOptions"
          label="Estatus"
          dense
          filled
          v-model="searchStatus"
          emit-value
          map-options
        />
      </div>
      <div class="col-lg-2 q-px-md">
        <q-input dense filled label="Factura" v-model="searchInvoice" />
      </div>
      <div class="col-lg-2 q-px-md">
        <q-select
          :options="absorbedCompanys"
          label="Compañia Absorbida"
          dense
          filled
          v-model="absorbedCompany"
          emit-value
          map-options
        />
      </div>
    </div>
    <div>
      <div class="row q-mb-xl">
        <div class="col q-px-md">
          <q-table
            :data="filterTableData"
            :columns="invoicesColumns"
            row-key="name"
            :pagination.sync="initialPagination"
            class="full-width my-sticky-header-table"
            title="Facturas"
            binary-state-sort
            :sort-method="customSort"
          >
            <template v-slot:header="props">
              <q-tr :props="props">
                <q-th
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                  >{{ col.label }}</q-th
                >
                <q-th>Reenviar</q-th>
              </q-tr>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td key="No" :props="props">
                  <router-link
                    :to="`/invoice-details/${props.row.id}`"
                    class="text-primary"
                    >{{ props.row.No }}</router-link
                  >
                </q-td>
                <q-td key="box" :props="props">
                  {{ props.row.box }}
                  <q-tooltip
                    anchor="bottom middle"
                    self="top middle"
                    content-class="bg-primary"
                    :offset="[10, 10]"
                  >
                    <div
                      class="text-subtitle2"
                      v-for="(user, index) in [
                        ...allUsers.find((user) => user.box === props.row.box),
                      ]"
                      :key="index"
                    >
                      <strong> {{ `${user.name} ${user.lastName}` }}</strong
                      ><br />
                      <strong> {{ `${user.email}` }}</strong>
                    </div>
                  </q-tooltip>
                </q-td>
                <q-td key="price" :props="props">
                  $ {{ props.row.price }}
                </q-td>
                <q-td key="status" :props="props">
                  <q-btn
                    :color="returnStatus(props.row.status).color"
                    class="text-white"
                    rounded
                    size="sm"
                    @click="assignWorkingInvoice(props.row)"
                  >
                    <i
                      :class="
                        `fas fa-${returnStatus(props.row.status).icon} q-mr-sm`
                      "
                    ></i>
                    {{ returnStatus(props.row.status).status }}
                  </q-btn>
                </q-td>
                <q-td key="packages" :props="props">
                  {{ returnPackagesAmount(props.row.packages) }}
                </q-td>

                <q-td key="date" :props="props">
                  {{ returnFormattedDate(props.row.creationTime) }}
                </q-td>

                <q-td auto-width>
                  <q-btn
                    size="sm"
                    color="primary"
                    round
                    dense
                    icon="fas fa-envelope"
                    flat
                  />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>
      <q-dialog v-model="statusDialog">
        <q-card style="width: 300px">
          <q-card-section>
            <div class="text-h6">Estado de factura</div>
          </q-card-section>
          <q-card-section>
            <div class="row">
              <q-btn
                filled
                color="red"
                label="Pendiente"
                class="full-width q-mb-md"
                @click="changeInvoiceStatus('unpaid')"
              />
            </div>
            <div class="row">
              <q-btn
                filled
                color="green"
                label="Pagado"
                class="full-width q-mb-md"
                @click="changeInvoiceStatus('paid')"
              />
            </div>
            <div class="row">
              <q-btn
                filled
                color="primary"
                label="Entregado"
                class="full-width q-mb-md"
                @click="changeInvoiceStatus('delivered')"
              />
            </div>
            <!-- <div class="row">
                            <q-btn
                                outline
                                color="amber"
                                label="..STATUS.."
                                class="full-width q-mb-md"
                                @click="changeInvoiceStatus('somethingelse')"
                            />
                        </div> -->
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </q-page>
</template>

<script>
import * as api from "@/api/api";
import dates from "@/mixins/dates";
import { collection, getDocs, onSnapshot, query } from "@firebase/firestore";
import moment from "moment";
import { firestore } from "../../main";
export default {
  mixins: [dates],
  data() {
    return {
      searchStatus: "",
      statusDialog: false,
      searchBox: "",
      searchInvoice: "",
      dateToday: "",
      dateTomorow: "",
      absorbedCompany: "",
      statusOptions: [
        {
          label: "Todo",
          value: "",
        },
        {
          label: "Pendiente",
          value: "unpaid",
        },
        {
          label: "Pagado",
          value: "payed",
        },
        {
          label: "Entregado",
          value: "delivered",
        },
      ],
      absorbedCompanys: [
        {
          label: "Todo",
          value: "",
        },
        {
          label: "Ernel",
          value: "Ernel",
        },
        {
          label: "Live Line",
          value: "LifeLine",
        },
      ],
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 10,
      },
      invoicesColumns: [
        {
          name: "No",
          align: "left",
          label: "Factura",
          field: "No",
          sortable: true,
        },
        {
          name: "box",
          align: "left",
          label: "Casillero",
          field: "box",
          sortable: true,
        },
        {
          name: "price",
          align: "left",
          label: "Monto ($)",
          field: "price",
          sortable: true,
        },
        {
          name: "status",
          align: "left",
          label: "Estado",
          field: "status",
          sortable: true,
        },
        {
          name: "packages",
          align: "left",
          label: "Paquetes (cant.)",
          field: "packages",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: "Facturado el",
          field: "date",
          sortable: true,
        },
      ],
      invoicesData: [],
      allUsers: [],
      workingInvoice: "",
      displayLoading: false,
      displayAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "",
    };
  },
  methods: {
    customSort(rows, sortBy, descending) {
      const data = [...rows];
      data.sort((a, b) => {
        let x = descending ? b : a;
        let y = descending ? a : b;
        switch (sortBy) {
          case "date":
            return parseInt(x["creationTime"]) - parseInt(y["creationTime"]);
          case "price":
            return parseFloat(x[sortBy]) - parseFloat(y[sortBy]);
          case "status":
            let status = {
              unpaid: 1,
              review: 2,
              paid: 3,
              delivered: 4,
            };
            return status[x[sortBy]] - status[y[sortBy]];
          case "box":
            return (
              parseInt(x[sortBy].replace("YGO", "")) -
              parseInt(y[sortBy].replace("YGO", ""))
            );
          case "packages":
            return parseInt(x[sortBy].length) - parseInt(y[sortBy].length);
          default:
            return parseInt(x[sortBy]) - parseInt(y[sortBy]);
        }
      });
      return data;
    },
    assignWorkingInvoice(invoice) {
      this.workingInvoice = invoice;
      this.statusDialog = true;
    },
    changeInvoiceStatus(status) {
      this.displayLoading = true;
      this.displayAlert = false;
      let obj = { ...this.workingInvoice };
      obj.status = status;
      let id = obj.id;
      delete obj.id;
      api
        .UpdateInvoiceInformationById({ id: id, invoice: obj })
        .then((response) => {
          this.displayLoading = false;
          this.alertTitle = "Exito!";
          this.alertMessage = "Se ha actualizado con exito";
          this.alertType = "success";
          this.displayAlert = true;
        })
        .catch((error) => {
          console.log(error);
          this.displayLoading = false;
          this.alertTitle = "Error";
          this.alertMessage = "Hubo un error con la peticion";
          this.alertType = "error";
          this.displayAlert = true;
        });
    },
    returnBoxId(box) {
      let userId = this.allUsers.find((user) => user.box === box);
      return userId !== undefined ? `/user-details/${userId.id}` : "NaN";
    },
    returnPackagesAmount(packages) {
      if (packages) return parseInt(packages.length);
    },
    addToData(id, data) {
      data.id = id;
      this.invoicesData.push(data);
    },
    editData(id, data) {
      data.id = id;
      this.invoicesData.forEach((d, index) => {
        if (d.id === id) {
          this.invoicesData.splice(index, 1, data);
        }
      });
    },
    removeData(id) {
      this.invoicesData.forEach((d, index) => {
        if (d.id === id) {
          this.invoicesData.splice(index, 1);
        }
      });
    },
    returnStatus(status) {
      if (status == "unpaid")
        return { status: "Pendiente", color: "red", icon: "dollar-sign" };
      if (status == "paid")
        return { status: "Pagado", color: "green", icon: "dollar-sign" };
      if (status == "review")
        return { status: "En Proceso", color: "amber", icon: "dollar-sign" };
      if (status == "delivered")
        return { status: "Entregado", color: "primary", icon: "box" };
    },
    returnAbsorbedComapanysBox(company) {
      if (company === "") return this.allUsers.map((user) => user.box);
      return this.allUsers
        .filter((user) => user.absorbedCompany === company)
        .map((user) => user.box);
    },
    returnIfItemIsInDateRange(pckg) {
      if (this.dateToday != "" || this.dateTomorow != "") {
        let dataDate = moment(pckg.creationTime).format("YYYY/MM/DD");
        return (
          moment(dataDate).isSameOrAfter(this.dateToday) &&
          moment(dataDate).isSameOrBefore(this.dateTomorow)
        );
      }
      return true;
    },
  },
  computed: {
    filterTableData() {
      let data = [];
      this.invoicesData.forEach((invoice) => {
        if (
          invoice.No.toString(10).includes(this.searchInvoice) &&
          this.returnIfItemIsInDateRange(invoice) &&
          invoice.box.includes(this.searchBox) &&
          this.returnAbsorbedComapanysBox(this.absorbedCompany).includes(
            invoice.box
          ) &&
          invoice.status.includes(this.searchStatus)
        )
          data.push(invoice);
      });
      return data.sort((a, b) =>
        this.sortDate(a.creationTime, b.creationTime, "desc")
      );
    },
  },
  watch: {
    invoicesData(newValue, oldValue) {
      this.filterInvoicesData = newValue;
    },
  },
  mounted() {
    this.$refs.box.focus();

    const q = query(collection(firestore, "invoices"));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            this.addToData(change.doc.id, change.doc.data());
          }
          if (change.type === "modified") {
            this.editData(change.doc.id, change.doc.data());
          }
          if (change.type === "removed") {
            this.removeData(change.doc.id);
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );

    api
      .ReturnAllUsers()
      .then((response) => (this.allUsers = response.data.data));
    api
      .returnAllInvoices()
      .then((response) => (this.invoicesData = response.data.data));
  },
};
</script>
