import { render, staticRenderFns } from "./PackageRegister.vue?vue&type=template&id=725d8c72&"
import script from "./PackageRegister.vue?vue&type=script&lang=js&"
export * from "./PackageRegister.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QFile,QIcon,QBtn,QSpace,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QTable,QTr,QTh,QCheckbox,QTd,QPopupEdit,QInput,QTooltip,QChip,QBtnGroup,QCard,QCardSection,QSelect,QPopupProxy,QDate,QCardActions,QDialog,QPageSticky});qInstall(component, 'directives', {ClosePopup});
